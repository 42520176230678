/* pdf download page */

@media print {
	body {
		height: 100%;
		width: 100%;
		background-color: #ff0000;
	}

	div {
		margin: 0;
		box-shadow: 0;
		page-break-inside: avoid;
		background-color: white;
		height: 100px;
		width: 100px;
	}
	div.check {
		background-color: white;
	}
}

.report-print {
	width: 3508px;
	height: 2480px;
}
