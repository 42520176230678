/* ./src/index.css */
@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
	display: none;
}

@layer components {
	.btn {
		@apply text-base font-semibold rounded p-3 text-white;
	}

	.btn--secondary {
		@apply text-black;
	}
	.graph-name {
		@apply text-sm font-medium text-white flex space-x-3;
	}
	.graph-dot {
		@apply h-4 w-4 rounded-full mt-0.5;
	}
}

.btn--primary {
	background: linear-gradient(
		88.09deg,
		#9e3b8c 0%,
		#c43462 28.81%,
		#dc314f 50.06%,
		#eb2e3b 65.26%,
		#f85137 84.56%,
		#fe954e 99.52%
	);
	color: white;
}
.btn--primary:disabled {
	background: linear-gradient(88.09deg, #a499a2 0%, #5c5b5a 99.52%);
	cursor: not-allowed;
}

.btn--border {
	/* padding: 1rem;
  padding: .5rem 3rem; */
	color: white;
	box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
	border: solid 1px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(
			88.09deg,
			#9e3b8c 0%,
			#c43462 28.81%,
			#dc314f 50.06%,
			#eb2e3b 65.26%,
			#f85137 84.56%,
			#fe954e 99.52%
		);
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px #303030 inset;
}

.btn--border-transparent {
	/* padding: 1rem;
	padding: .5rem 3rem; */
	color: white;
	box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
	border: solid 1px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(
			88.09deg,
			#9e3b8c 0%,
			#c43462 28.81%,
			#dc314f 50.06%,
			#eb2e3b 65.26%,
			#f85137 84.56%,
			#fe954e 99.52%
		);
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px #161616 inset;
}

.bg--primary {
	background: #161616;
}

.bg--secondary {
	background: #222222;
}

.bg--gray {
	background: #303030;
}

.bg--black {
	background: #1a1919;
}
.color--primary {
	background: -webkit-linear-gradient(
		88.09deg,
		#9e3b8c 0%,
		#c43462 28.81%,
		#dc314f 50.06%,
		#eb2e3b 65.26%,
		#f85137 84.56%,
		#fe954e 99.52%
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.input--primary {
	background: #222222 !important;
	color: white !important;
	border-radius: 4px;
}

.pink--primary {
	background: #dc314f;
}

/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #eb2e3b #d1d5db;
	font-family: "Poppins", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
}

.ant-form-item-control-input input {
	background: #222222;
}

button {
	cursor: pointer;
}

/* https://www.w3generator.com/scrollbar */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
::-webkit-scrollbar-thumb {
	background: #df3b3b;
	border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover {
	background: #eb2e3b;
}
::-webkit-scrollbar-track {
	background: #ffffff80;
	border-radius: 7px;
	box-shadow: inset 7px 10px 12px #f0f0f0;
}

.gradient-blue {
	background: linear-gradient(180deg, #89e5fe 0%, #46c6eb 100%);
	box-shadow: 6px 5px 16px -8px rgb(74 74 74 / 75%);
}

#dashboard .ant-table-wrapper .ant-pagination {
	padding-right: 1rem;
}

.ant-tabs-ink-bar {
	height: 8px;
	background: #3133da !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #3133da;
}

.ant-tabs-tab {
	min-width: 60px;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
	color: #4a4ddd;
}

.ant-menu {
	background: transparent;
}

.ant-menu-item {
	margin: 12px;
	border-radius: 6px;
	background-color: transparent;
}

.ant-menu-item a {
	color: white;
}

.ant-menu-item .ant-menu-item-selected {
	background-color: #313131 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #313131 !important;
}

.ant-menu-item .ant-menu-item-selected a {
	color: white;
}

.ant-menu-item.ant-menu-item-selected .anticon.ant-menu-item-icon svg {
	color: white !important;
}

.ant-menu-inline .ant-menu-item::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	content: "";
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
	color: white;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
	width: auto;
}

.site-layout .site-layout-background {
	background: #fff;
}

.ant-notification-notice {
	border-radius: 8px;
	background-color: #1f1f1f;
	color: white;
}

.ant-notification-notice-message {
	color: white;
}

.ant-notification-notice-close {
	color: white;
}

.date {
	box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
	border: solid 1px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(
			88.09deg,
			#9e3b8c 0%,
			#c43462 28.81%,
			#dc314f 50.06%,
			#eb2e3b 65.26%,
			#f85137 84.56%,
			#fe954e 99.52%
		);
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px #303030 inset;
	border-radius: 3px;
	/* padding: 8px; */
	color: white;
	background-color: transparent;
}
.date .ant-picker-input > input,
.date .ant-picker-suffix {
	color: white;
}

.access-log table th {
	background-color: #4a4cce;
	color: white;
	text-align: center;
}

table tbody tr td {
	text-align: center;
}

.site-description-item-profile-wrapper {
	margin-bottom: 7px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
}

[data-theme="compact"] .site-description-item-profile-wrapper {
	font-size: 12px;
	line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
	display: block;
	margin-bottom: 16px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 1.5715;
}

[data-theme="compact"] .ant-drawer-body p.site-description-item-profile-p {
	font-size: 14px;
	line-height: 1.66667;
}

.site-description-item-profile-p-label {
	display: inline-block;
	margin-right: 8px;
	color: rgba(0, 0, 0, 0.85);
}

.gradient-bg {
	background: linear-gradient(285deg, #faf4f9, #f3f8fc 80%);
}

#logs .ant-table-thead .ant-table-filter-trigger-container .active {
	color: white;
}

#logs .ant-table-thead .ant-table-filter-trigger-container:hover,
#logs
	.ant-table-thead
	.ant-table-filter-trigger-container:hover
	.ant-dropdown-open {
	background-color: #3b3cac;
}

/* Ant sidebar custom styles */
.ant-layout-sider {
	background: #222222 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	border-right: none;
}

.ant-input-password-icon {
	color: white !important;
}

.ant-input {
	color: white !important;
}

.ant-input:hover {
	border-color: transparent;
	border-right-width: 0px !important;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
	background: #222222 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	border-color: transparent;
	border-right-width: 0px !important;
}

.ant-table-thead .ant-table-cell {
	background: #1c1c1c;
	color: white;
	margin-bottom: 5px;
}

.ant-table-cell {
	background: transparent;
	color: white;
	border-bottom: 1px solid #f0f0f030;
}

.ant-table-thead {
	border-radius: 30px;
	overflow: hidden;
}

.ant-table-row,
.ant-table-tbody > tr.ant-table-row:hover > td {
	background: #222222;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
	border-bottom: 1px solid #313131;
}

.ant-form label {
	color: white;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background: transparent;

	border: none;
}
.ant-select {
	border-radius: 4px;
}
.ant-select-arrow {
	color: white;
}
.ant-form-item-has-error
	.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
	.ant-select-selector {
	background: #222222;
}

.ant-timeline {
	color: white;
}

.ant-timeline-item .ant-timeline-item-tail {
	border-left: 1px solid #f0f0f081;
}

.ant-timeline-item {
	padding-bottom: 40px;
}
.ant-timeline-item-head-custom {
	line-height: 0;
	border-radius: 100%;
	padding: 3px;
	background: none;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	background: transparent;
	color: white;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
	border-color: gray;
}

.ant-pagination-item,
.ant-pagination-item a {
	background: transparent;
	color: white;
}

.ant-pagination-item-active {
	background: #303030;
}

.ant-table-tbody {
	background: #222222;
}

.ant-table-filter-dropdown-search input {
	color: black !important;
}

.select--primary {
	background: -webkit-linear-gradient(
		0deg,
		#9e3b8c 0%,
		#c43462 28.81%,
		#dc314f 50.06%,
		#eb2e3b 65.26%,
		#f85137 84.56%,
		#fe954e 99.52%
	);
	color: white;
}
.date {
	/* background: -webkit-linear-gradient(
		0deg,
		#9e3b8c 0%,
		#c43462 28.81%,
		#dc314f 50.06%,
		#eb2e3b 65.26%,
		#f85137 84.56%,
		#fe954e 99.52%
	);
	color: white;
	border-radius: 5px; */
	/* border-color: linear-gradient(
		88.09deg,
		#9e3b8c 0%,
		#c43462 28.81%,
		#dc314f 50.06%,
		#eb2e3b 65.26%,
		#f85137 84.56%,
		#fe954e 99.52%
	); */
	box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
	border: solid 1px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(
			88.09deg,
			#9e3b8c 0%,
			#c43462 28.81%,
			#dc314f 50.06%,
			#eb2e3b 65.26%,
			#f85137 84.56%,
			#fe954e 99.52%
		);
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px #303030 inset;
	border-radius: 3px;
	/* padding: 8px; */
	color: white;
	background-color: transparent;
}
.date .ant-picker-input > input,
.date .ant-picker-suffix {
	color: white;
}

.number {
	-moz-appearance: textfield;
}

.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

progress {
	width: 100%;
	height: 5px;
	@apply bg-gray-300 rounded-md;
}
progress::-webkit-progress-bar {
	width: 100%;
	height: 5px;
	@apply bg-gray-300 rounded-md;
}
progress::-webkit-progress-value {
	@apply bg-indigo-500 rounded-md;
}
progress::-moz-progress-bar {
	width: 100%;
	height: 5px;
	@apply bg-gray-300 rounded-md;
}

/* .anticon.anticon-loading {
	font-size: 48px !important;
} */
