.color-circle {
	@apply w-6 h-6 rounded-full cursor-pointer;
}

.power-btn {
	filter: drop-shadow(0px 0px 2px rgba(223, 13, 13, 0.534));
	transition: all 0.2s ease-in-out;
}

.power-btn:hover {
	filter: drop-shadow(0px 0px 2px rgb(235, 2, 2));
}

/* USER LIST STYLES */
#user-list th {
	background-color: white;
	color: gray;
}
#user-list td {
	text-align: start;
}

.ant-upload {
	width: 100%;
}

.button-container {
	/* position: fixed; */
	padding: 10px;
	display: flex;
	justify-content: space-between;
	overflow: hidden;
}

.button-text-container {
	order: -1;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap; /*Keep text always one line*/
	overflow: hidden;
	width: 0;
	transition: width 1s;
}

.button-icon-container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.button-icon-container:hover + .button-text-container {
	width: 200px;
}

.button-text-container:hover {
	width: 200px;
}

.rounded-card {
	box-shadow: inset 2px -1px 10px rgba(254, 255, 254, 0.1);
	border-radius: 20px 5px 20px 5px;
	color: white;
	background: #161616;
}

.card-piechart {
	box-shadow: inset 2px -1px 10px rgba(254, 255, 254, 0.1);
	border-radius: 20px;
	color: white;
	background: #161616;
}

.device-card {
	border-radius: 15px;
	color: white;
	background: #161616;
}

.piechart {
	color: white;
	background: #4d4c4c;
	border-radius: 10px;
}

.bg-color {
	background-color: #252525;
}
.card {
	box-shadow: inset 2px -1px 10px rgba(254, 255, 254, 0.247);
	color: white;
}

.device-card h1 {
	color: white;
}

.gradient-border {
	position: relative;
}
.gradient-border::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 20px 5px 20px 5px;
	padding: 2px;
	background: linear-gradient(
		88.09deg,
		#9e3b8c 0%,
		#c43462 28.81%,
		#dc314f 50.06%,
		#eb2e3b 65.26%,
		#f85137 84.56%,
		#fe954e 99.52%
	);
	-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	mask-composite: exclude;
}

.ant-pagination-options .ant-select {
	color: white;
	border: 1px solid white;
}

.login-bg {
	animation: float 10s ease-in-out infinite;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translatey(0px);
	}
}

/* Notification */
.notification-bg {
	background-color: #393939;
}

.notification-bg .hover {
	background-color: #2d2d2d8f;
}
